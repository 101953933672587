/* eslint-disable no-redeclare */
/* eslint-disable block-scoped-var */

'use strict';

// Not SFRA 6x script upgrade compatible.Selective changes applied.

var baseSearch = require('base/search/search');
var states = require('improove/util/states');

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        var el = ($("[data-id='" + option.id + "']"));
        el.attr('data-url', option.url);
    });
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar',
        '.search-result-count'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}

// #ADDIN
function appendPageSize(loadBtnElem) {
    var loadingButton = $(loadBtnElem),
        gridUrl = loadingButton.attr('data-url');

    var szString = 'sz=',
        startString = '&start=',
        numberProductsPerPage = window.SitePreferences ? window.SitePreferences.DEFAULT_PAGE_SIZE : 36;

    // Set Page Size value from Load More Button
    var startSubString = gridUrl.substr(gridUrl.indexOf(startString) + startString.length),
        sizeString = startSubString.substr(0, startSubString.indexOf('&')),
        sizeValue = parseInt(sizeString, 10) + numberProductsPerPage;

    var url = '';

    if (window.location.search.indexOf('?') >= 0) {
        if (window.location.search.indexOf(szString) >= 0 && window.location.search.indexOf('&srule') < 0) {
            // Replace Page Size value in the URL
            var sizeIndex = window.location.search.indexOf(szString),
                startIndex = window.location.search.indexOf(startString);

            url = window.location.search.substr(0, sizeIndex + 3) + sizeValue + window.location.search.substr(startIndex);

            history.replaceState(null, document.title, url);
        } else if (window.location.search.indexOf('&srule') >= 0 && window.location.search.indexOf('&start') >= 0) {
            // Append Page Size value to the URL (the URL has sort parameters)
            var sortSubstrUrl = window.location.search.substr(0, window.location.search.indexOf('&start'));
            url = window.location.pathname + sortSubstrUrl + startString + '0&' + szString + sizeValue;

            history.pushState(null, document.title, url);
        } else {
            // Append Page Size value to the URL (the URL has filters parameters)
            url = window.location.pathname + window.location.search + '&' + szString + sizeValue + startString + '0';

            history.pushState(null, document.title, url);
        }
    } else {
    // Append Page Size value to the URL
        url = window.location.pathname + window.location.search + '?' + szString + sizeValue + startString + '0';

        history.pushState(null, document.title, url);
    }
}

// #ADDIN
function appendFilterUrl(filterButton) {
    var filterBtn = $(filterButton),
        filterUrl = filterBtn.attr('data-href'),
        filterString = '';

    if (filterUrl.indexOf('q') >= 0) {
        filterString = filterUrl.substring(filterUrl.indexOf('q'));
    } else if (filterUrl.indexOf('&') >= 0) {
        filterString = filterUrl.substring(filterUrl.indexOf('&') + 1);
    }

    var url = '';

    if (window.location.search === '') {
        url = window.location.pathname + window.location.search + '?' + filterString;
        history.pushState(null, document.title, url);
    } else if (window.location.search.indexOf('?q') >= 0) {
        url = window.location.pathname + '?' + filterString;
        history.pushState({}, document.title, url);
    } else if (window.location.search.indexOf('&pref') >= 0 || window.location.search.indexOf('?sz') >= 0 || window.location.search.indexOf('?pref') >= 0) {
        history.pushState({}, document.title, '?' + filterString);
    }
}

function appendSortUrl(sortButton) {
    var sortBtn = $(sortButton),
        sortUrl = sortBtn.attr('data-url'),
        sortString = '';

    if (sortUrl.indexOf('&') >= 0) {
        sortString = sortUrl.substr(sortUrl.indexOf('&') + 1);
    }

    var url = '';

    if (window.location.search === '') {
        url = window.location.pathname + window.location.search + '?' + sortString;
        history.pushState(null, document.title, url);
    } else if (window.location.search.indexOf('?q') >= 0 || window.location.search.indexOf('&pref') >= 0 || window.location.search.indexOf('?sz') >= 0 || window.location.search.indexOf('?pref') >= 0) {
        history.pushState({}, document.title, '?' + sortString);
    }
}

function sortTextOnPageLoad() {
    var activeSortFilterText = $('.dropdown [selected]').text();

    if (activeSortFilterText !== '') {
        $('.dropdown-header .title').text(activeSortFilterText);
    }
}

baseSearch.toogleDescription = function () {
    $(document).ready(function () {
        $('.show-full-description, .show-less-description').on('click', function (e) {
            e.preventDefault();
            $(this).siblings().toggleClass('hide');
            $(this).toggleClass('hide');
        });
    });
};

// #ADDIN
baseSearch.updateProductCount = function () {
    $('body').on('filter:update', function (e, response) {
        $('.sorting-count').html($(response).find('[data-sort-options]').attr('data-product-count'));
    });
};

// #ADDIN
baseSearch.addState = function () {
    states.add('filter', $('.state-container--filter'), $('.filter-state-trigger'), {});
};

// #CDIFF #MONITOR - extended SFRA version
baseSearch.sort = function () {
    // Handle sort order menu selection
    $('.container').on('click', '[name=sort-order]', function (e) {
        e.preventDefault();
        e.stopPropagation();

        appendSortUrl($(this));
        $(this).trigger('search:sort', this.dataset.url);
        var $text = $(this).text();
        $.ajax({
            url: this.dataset.url,
            data: { selectedUrl: this.dataset.url, sort: 'sort' },
            method: 'GET',
            success: function (response) {
                $('.dropdown-header .title').text($text);
                $('.dropdown').removeClass('active');
                parseResults(response);
            }
        });
    });
};

baseSearch.sortTextOnPageLoad = sortTextOnPageLoad();

// #CDIFF #MONITOR - extended SRFA version
baseSearch.applyFilter = function () {
    // Handle refinement value selection and reset click
    $('.container').on(
        'click',
        '.refinements li label, .refinements li button, .refinement-bar a.reset, .filter-value button, .swatch-filter button',
        function (e) {
            e.preventDefault();
            e.stopPropagation();

            // Smoother handling of filters
            $('.refinements').addClass('loading');
            $(this).siblings('input[type=checkbox]').prop('checked', !$(this).siblings('input[type=checkbox]').prop('checked'));
            $([document.documentElement, document.body]).animate({
                scrollTop: $('#filterScroll').offset().top
            }, 500);

            appendFilterUrl($(this));

            $(this).trigger('search:filter', e);
            var scrollFilterTop = $(this).closest('.refinement.active .card-body').scrollTop();
            $.ajax({
                url: $(this).data('href'),
                data: {
                    page: $('.grid-footer').data('page-number'),
                    selectedUrl: $(this).data('href')
                },
                method: 'GET',
                success: function (response) {
                    $('.refinements').removeClass('loading');
                    parseResults(response);
                    updateSortOptions(response);
                    $('body').trigger('filter:update', response);
                    sortTextOnPageLoad();
                    $('.refinement.active .card-body').scrollTop(scrollFilterTop);
                },
                error: function () {
                    $('.refinements').removeClass('loading');
                }
            });
        });
};

baseSearch.showMore = function () {
    // Show more products
    $('.container').on('click', '.show-more button', function (e) {
        e.stopPropagation();
        var showMoreUrl = $(this).data('url');

        e.preventDefault();
        appendPageSize($(this)); // #CDIFF #MONITOR line

        $(this).trigger('search:showMore', e);
        $.ajax({
            url: showMoreUrl,
            data: { selectedUrl: showMoreUrl },
            method: 'GET',
            success: function (response) {
                try {
                    // #REFACTOR potential place to refactor
                    sessionStorage['scroll-cache_' + showMoreUrl] = response;
                } catch (y) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax
                }
                $('.grid-footer').replaceWith(response);
                updateSortOptions(response);
            }
        });
    });
};

$('.filter-categories').on('click', function () {
    $('body').addClass('state--categories');
});

$('.filter-state-buttons-cancel').on('click', function () {
    $('body').removeClass('state--categories');
});

var searchedKeywords = $('div.search-query').attr('data-value');
$('.header-search-input').val(searchedKeywords);

function setCookie(key, value, expiry) {
    var expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
}

function getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
        let [key, value] = el.split('=');
        cookie[key.trim()] = value;
    });
    return cookie[cookieName];
}

function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
}

var pageNumberText = $('#pageNumberText').val();
var pageTitle = $('#pageTitle').val();

$('body').on('click', 'a', function () {
    if (!$(this).hasClass('grid-footer-pagination-list-item-link')) {
        eraseCookie('pagination');
        eraseCookie('paginationPage');
    }
});


$('body').on('click', '[data-pagination-link]', function () {
    var _this = $(this);
    var selectedPageNumber = parseInt(_this.text());
    var message = pageNumberText + ' ' + selectedPageNumber + ' - ' + pageTitle;
    setCookie('pagination', message, '1');
    setCookie('paginationPage', selectedPageNumber, '1');
});

$('body').on('click', '[data-pagination-link-prev]', function () {
    var paginationPage = getCookie('paginationPage');
    var message = pageNumberText + ' ' + parseInt(paginationPage - 1) + ' - ' + pageTitle;
    setCookie('pagination', message, '1');
    setCookie('paginationPage', parseInt(paginationPage - 1), '1');
});

$('body').on('click', '[data-pagination-link-next]', function () {
    var paginationPage = getCookie('paginationPage');


    if (paginationPage) {
        var message = pageNumberText + ' ' + (parseInt(paginationPage) + 1) + ' - ' + pageTitle;
        setCookie('pagination', message, '1');
        setCookie('paginationPage', (parseInt(paginationPage) + 1), '1');
    } else {
        paginationPage = 1;
        var message = pageNumberText + ' ' + parseInt(paginationPage + 1) + ' - ' + pageTitle;
        setCookie('pagination', message, '1');
        setCookie('paginationPage', parseInt(paginationPage + 1), '1');
    }
});

$(document).ready(function () {
    if ($('.grid-footer-pagination')[0]) {
        var paginationCookie = getCookie('pagination');
        if (paginationCookie) {
            document.title = paginationCookie;
        }
    }
});

$('body').on('click', '[data-pagination-link-category]', function () {
    eraseCookie('pagination');
    eraseCookie('paginationPage');
});

module.exports = baseSearch;
